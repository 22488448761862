@font-face {
  font-family: Quicksand;
  src: url(./Font/Quicksand.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;
  -webkit-tap-highlight-color: transparent;
  --hero-height: 100svh;
}

body {
  background-color: #f0f0f0;
  background-image: url(./Img/background.svg);
  overflow-x: hidden;
}

body:has(a.env-active) {
  background-color: #eaf2ed;
}

:root {
  font-size: clamp(14px, 11px + 0.3333vw, 16px);
}

::selection {
  color: #fff;
  background-color: #000;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='number']:focus,
textarea:focus {
  outline: 2px solid #000;
}


@media screen and (max-width: 650px) {
  * {
    --hero-height: calc(100svh - 6.13rem);
  }
}