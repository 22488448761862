.cart-checkout-container {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    gap: 1.5rem;
}

.cart-items-container {
    width: min(30rem, 50% - 0.75rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cart-item-container {
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
}

.cart-item-container>form {
    display: none;
}

.cart-item-container .product-info-card.disable {
    pointer-events: none;
}

.total-info-container {
    width: min(30rem, 50% - 0.75rem);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.checkout-container-wrapper {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
}

.checkout-container .discount .promo-div input[name="intent"] {
    display: none;
}

@media screen and (max-width: 1050px) {
    .cart-checkout-container {
        flex-direction: column;
        align-items: center;
    }

    .cart-items-container {
        width: min(30rem, 100%);
    }

    .total-info-container {
        width: min(30rem, 100%);
    }
}