.details-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    gap: 2rem;
    padding-inline: 2rem;
    position: relative;
    margin-bottom: 3rem;
}

.buy-back-icon {
    position: fixed;
    top: 6.15rem;
    left: 2rem;
    z-index: 98;
    font-size: 1.25rem;
    color: #eaeaea;
    background-color: #000;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.buy-back-icon:hover {
    background-color: #353535;
}

.buy-back-icon:active {
    transform: scale(0.95);
}

.details-content-container {
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 3rem;
}

.details-content-container>h1 {
    font-size: 2.25rem;
}

.review-rating-div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.rating-div {
    display: flex;
    gap: 0.25rem;
}

.details-rating {
    font-size: 1.15rem;
}

.review-rating-div p {
    font-weight: 500;
    font-size: 0.95rem;
}

.details-content-container>h2 span {
    font-size: 0.95rem;
}

.details-content-container>p {
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 150%;
}

.quantity-size {
    display: flex;
    gap: 4rem;
}

.size-container {
    width: fit-content;
}

.size-container p {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
}

.subscribe-div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    position: relative;
}

.subscribe-div>label {
    height: 1.8rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.subscribe-div>label p {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 3px;
}

.subscribe-div>label input {
    height: 1.5rem;
    width: 1.5rem;
    accent-color: #000;
    cursor: pointer;
    transition: transform 0.25s ease;
}

.subscribe-div>label input:active {
    transform: scale(0.95);
}

.subscribe-div>p {
    position: absolute;
    left: 0;
    top: 105%;
    font-size: 0.85rem;
    font-weight: 500;
}

.frequency-div {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    position: relative;
}

.manage-sub {
    width: 7rem;
    aspect-ratio: 63 / 16;
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manage-sub:hover {
    background-color: rgba(215, 215, 215, 0.5);
}

.frequency-div>p {
    position: absolute;
    bottom: 125%;
    right: 0;
    z-index: 1;
    background: #ededed;
    border-radius: 6.5px;
    box-shadow: 0 0 30px #c0c0c0;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 150%;
    white-space: nowrap;
    padding: 0.35rem 0.65rem;
    transform-origin: bottom right;
    transform: scale(0);
    transition: transform 0.25s ease;
    pointer-events: none;
}

.frequency-div .info-icon {
    font-size: 1.15rem;
    margin-bottom: 1px;
    cursor: pointer;
}

.frequency-div .info-icon:hover+p {
    transform: scale(1);
}

.add-to-cart-form {
    width: 100%;
}

.add-to-cart-form input {
    display: none;
}

.details-content-container>button,
.add-to-cart-form button,
.login-options-container button {
    padding-block: 1rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    color: #eaeaea;
    background-color: #000;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.details-content-container>button:hover,
.add-to-cart-form button:hover,
.login-options-container button:hover,
.login-options-close:hover {
    background-color: #353535;
}

.details-content-container>button:active,
.add-to-cart-form button:active,
.login-options-container button:active,
.login-options-close:active {
    transform: scale(0.95);
}

.login-options-container {
    width: min(22rem, 95vw - 6rem);
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    scale: 0;
    position: relative;
    transition: scale 0.25s ease;
}

.login-options-container.show {
    scale: 1;
}

.login-options-close {
    position: absolute;
    top: 1rem;
    right: -3rem;
    font-size: 1.25rem;
    height: 2rem;
    width: 2rem;
    background-color: #000;
    border-radius: 50%;
    color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.25s ease;
}

.login-options-container p {
    font-size: 0.9rem;
    font-weight: 500;
}

.login-options-container button {
    margin-top: 0;
}

.login-options-container form {
    width: 100%;
}

.login-options-container form input {
    display: none;
}

.details-content-container>h2:last-of-type {
    margin-top: 0.5rem;
}

.reviews-wrapper {
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #000;
}

@media (pointer: coarse) {
    .buy-back-icon:hover {
        background-color: #000;
    }

    .buy-back-icon:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .manage-sub:hover {
        background-color: transparent;
    }

    .manage-sub:active {
        background-color: rgba(215, 215, 215, 0.5);
    }

    .details-content-container>button:hover,
    .add-to-cart-form button:hover,
    .login-options-container button:hover,
    .login-options-close:hover {
        background-color: #000;
    }

    .details-content-container>button:active,
    .add-to-cart-form button:active,
    .login-options-container button:active,
    .login-options-close:active {
        background-color: #353535;
        transform: scale(0.95);
    }
}

@media screen and (max-width: 760px) {
    .details-container {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    .buy-back-icon {
        position: absolute;
        top: 1rem;
    }

    .details-content-container {
        width: min(30rem, 100%);
    }
}