.message-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 3rem 2.22rem;
}

.doodle-div {
    width: 20rem;
}

.message-form {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.message-form.disable {
    pointer-events: none;
}

.message-form h2 {
    width: 100%;
    margin-left: 0.5rem;
    font-size: 1.65rem;
}

.input-div {
    position: relative;
    width: 100%;
}

.message-form input,
.message-form textarea {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2rem;
    border-radius: 10px;
    border: none;
    font-weight: 500;
}

.message-form textarea {
    resize: vertical;
}

.input-icon {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    font-size: 1rem;
    color: #656565;
}

.message-btn-div {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.message-btn-div button {
    width: 12.5rem;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;
    background-color: black;
    font-size: 1.1rem;
    font-weight: 500;
    color: #eaeaea;
    cursor: pointer;
    transition: transform 0.25s ease, background-color 0.25s ease;
}

.message-btn-div button:hover {
    background-color: #353535;
}

.message-btn-div button:active {
    transform: scale(0.95);
}

.message-btn-div h4 {
    color: green;
}

@media (pointer: coarse) {
    .message-btn-div button:hover {
        background-color: black;
    }

    .message-btn-div button:active {
        background-color: #353535;
    }
}