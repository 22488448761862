.listings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    padding-top: 1rem;
}

.products-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10.5rem, 1fr));
    gap: 2rem;
    padding: 0 2rem 2rem 2rem;
}

@media screen and (max-width: 800px) {
    .products-container {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 650px) {
    .listings-container {
        padding-top: 0;
    }

    .products-container {
        padding: 0 1.5rem 2rem 1.5rem;
    }
}