.environment-intro-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-inline: 2rem;
    margin-block: 5rem;
}

.environment-intro-container h1 {
    color: #0d3e30;
    text-align: center;
    font-size: 2.5rem;
}

.about-environment-wrapper {
    width: 100%;
    min-height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-environment-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: clamp(2rem, 8.6667vw - 50px, 5rem);
}

.about-environment-img-wrapper {
    max-width: 34rem;
    flex: 1 1 27.25rem;
}

.about-environment-img-wrapper img {
    width: 100%;
    border-radius: 20px;
}

.about-environment-sub-container {
    max-width: 33rem;
    flex: 1 1 27.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 2rem;
}

.about-environment-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.5rem;
    color: #0d3e30;
}

.about-environment-div h1 {
    font-size: 2.25rem;
}

.about-environment-div p {
    font-size: 0.9rem;
    font-weight: 500;
}

.environment-intro-container>h2 {
    color: #0d3e30;
    text-align: center;
    max-width: 60rem;
    font-weight: 550;
}

@media screen and (max-width: 850px) {
    .about-environment-wrapper {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .environment-intro-container>h2 {
        max-width: 45rem;
    }
}

@media screen and (max-width: 650px) {

    .environment-intro-container h1 {
        font-size: 2rem;
    }

    .about-environment-div p {
        font-size: 1rem;
    }
}