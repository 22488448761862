.placed-container {
    margin-block: auto;
    width: min(25rem, 90vw);
    border-radius: 20px;
    border: 2px solid #d0d0d0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 2rem;
}

.placed-container .already-placed-icon {
    font-size: 10rem;
    color: crimson;
}

.placed-container h2 {
    white-space: nowrap;
}

.placed-container a {
    text-decoration: none;
    text-align: center;
}