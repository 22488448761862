.process-hero {
    width: 100%;
    min-height: var(--hero-height);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    position: relative;
}

.process-img {
    width: clamp(20rem, 75%, 25rem);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
    position: relative;
}

.filled-logo {
    position: absolute;
    left: 0;
    bottom: 0.2rem;
    width: 100%;
    -webkit-mask-image: url(../../Img/logo.svg);
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center bottom;
    mask-image: url(../../Img/logo.svg);
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center bottom;
}

.pouring-bottle {
    position: absolute;
    width: 40%;
    top: 4.65%;
    right: -7.5%;
}