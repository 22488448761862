.home-hero {
    width: 100%;
    height: var(--hero-height);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    overflow-x: hidden;
    position: relative;
}

.hero-logo-div {
    width: clamp(22rem, 62vw, 25rem);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.35));
    transform: translateX(3rem);
}

.oat {
    transform-origin: 64.1px 46.4px;
}

.oat1 {
    animation: wave 4s linear infinite alternate;
}

.oat2 {
    animation: wave 5.5s linear infinite alternate;
}

.oat3 {
    animation: wave 7s linear infinite;
}

@keyframes wave {

    25% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(4deg);
    }
}

.scroll {
    position: absolute;
    left: 50%;
    bottom: 14rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(-45deg) translateX(-100%);
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    animation: arrows 4s linear infinite;
}

.scroll::after,
.scroll::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    position: absolute;
}

.scroll::after {
    transform: translate(-1rem, 1rem);
    animation: arrows 4s linear 1.33s infinite;
}

.scroll::before {
    transform: translate(-2rem, 2rem);
    animation: arrows 4s linear 2.66s infinite;
}

@keyframes arrows {
    33% {
        border-left-color: black;
        border-bottom-color: black;
    }

    66% {
        border-left-color: transparent;
        border-bottom-color: transparent;
    }
}