.product-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.product-hero {
    padding-top: 5.15rem;
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.product-hero>h1 {
    font-size: 5.625rem;
}

.nushoat-div {
    height: calc(100svh - 15rem);
    aspect-ratio: 16 / 49;
    position: relative;
}

.nushoat-div>img {
    width: 104%;
    margin-left: -4px;
}

.label-div {
    position: absolute;
    left: 0;
    bottom: 13%;
    width: 100%;
    mix-blend-mode: multiply;
}

.product-info-container {
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.oatmilk-bottle-div {
    height: calc(100svh - 15rem);
    aspect-ratio: 16 / 49;
}

.info-div {
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.info-div.last {
    margin-bottom: 2.5rem;
}

.nutrition-div {
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 1rem;
}

.nutrition-div svg {
    height: 4rem;
}

.info-div p {
    line-height: 125%;
}

.info-div .free-div {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.info-div .free-div svg {
    width: 5rem;
    height: 5rem;
}

@media screen and (max-width: 750px) {

    .product-hero>h1 {
        text-align: center;
        font-size: clamp(2.5rem, 12vw - 10px, 4rem);
    }

    .product-hero,
    .product-info-container {
        flex-direction: column;
    }

    .nushoat-div {
        --height-value: clamp(20rem, 544px - 48vw, 26rem);
        height: calc(100svh - var(--height-value));
    }

    .oatmilk-bottle-div {
        height: calc(100svh - 20rem);
    }

    .info-div {
        width: 90%;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        gap: 2rem;
    }

    .nutrition-div {
        justify-content: center;
    }

    .nutrition-div svg {
        height: 3.5rem;
        margin-top: 0.5rem;
    }

    .info-wrapper {
        flex-shrink: 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        will-change: transform;
    }

    .info-wrapper p {
        line-height: 135%;
    }

    .info-wrapper .free-div {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;
    }

    .info-wrapper .free-div svg {
        width: 4rem;
        height: 4rem;
    }

    .wrapper-first {
        margin-left: calc(var(--margin-value) * 1% + (var(--margin-value) / 100 * 2rem));
    }

}

@media screen and (max-width: 650px) {
    .product-hero {
        padding-top: 0;
        padding-bottom: clamp(6.13rem, 199.57px - 17.5vw, 8.63rem);
    }

    .info-div {
        padding-bottom: 1rem;
    }
}