.buy-container {
    width: 100%;
    display: flex;
    margin-top: 5.15rem;
}

.buy-nav {
    width: 17rem;
    height: calc(100svh - 5.15rem);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    padding: 2rem 1rem;
    border-top-right-radius: 10px;
    border: 2px solid #d0d0d0;
    border-left: none;
    position: sticky;
    top: 5.15rem;
    background-color: #f0f0f0;
}

.buy-nav.hide {
    display: none;
}

.buy-nav a {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

.buy-nav a div {
    display: flex;
    height: 3rem;
    justify-content: start;
    align-items: center;
    gap: 0.8rem;
    border-radius: 10px;
    padding: 0 0.5rem 0 0.75rem;
}

.item-icon {
    font-size: 1.1rem;
}

.buy-nav a div h5 {
    font-weight: 600;
}

.chevron-icon {
    font-size: 0.75rem;
    margin-left: auto;
}

.cart {
    position: relative;
}

.cart:not([data-cartitems=''])::after {
    content: attr(data-cartitems);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eaeaea;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: darksalmon;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
}

.buy-nav a.active div {
    background-color: #000;
    color: #eaeaea;
}

.buy-nav a.active div .chevron-icon {
    display: none;
}

.buy-nav a.active.cart::after {
    color: #000;
}

.buy-nav .account-div {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.account-div a {
    position: relative;
    transition: transform 0.5s ease;
}

.account-div a div {
    background-color: #f0f0f0;
    border: 2px solid #000;
}

.account-div .orders {
    pointer-events: none;
    transform: translateY(3rem) scale(0.95);
}

.account-div .addresses {
    pointer-events: none;
    transform: translateY(6rem) scale(0.9);
}

.account-div:has(.active) a {
    pointer-events: initial;
    transform: translateY(0) scale(1);
}

.buy-outlet {
    width: 100%;
}

.outlet-wrapper {
    width: 100%;
    min-height: calc(100svh - 5.15rem);
    display: flex;
    align-items: stretch;
}

.outlet-wrapper:has(.more-nav) {
    flex-direction: column;
}

@media screen and (max-width: 650px) {

    .buy-container {
        margin-top: 0;
        margin-bottom: 3.5rem;
    }

    .buy-nav {
        position: fixed;
        top: unset;
        bottom: 0;
        z-index: 99;
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: center;
        gap: 0;
        padding: 0;
        border-radius: 0;
        border: none;
        box-shadow: 0 -5px 30px #e0e0e0;
    }

    .buy-nav a div {
        height: 3.5rem;
        flex-direction: column;
        justify-content: center;
        gap: 0.1rem;
        border-radius: 0;
        padding: 0;
        transition: background-color 0.25s ease;
    }

    .buy-nav a.active div {
        background-color: transparent;
        color: initial;
        border-top: 2px solid black;
    }

    .buy-nav a div:active {
        background-color: rgba(0, 0, 0, 0.25);
    }

    .item-icon {
        font-size: 1.5rem;
    }

    .buy-nav a div h5 {
        font-size: 0.7rem;
        user-select: none;
    }

    .chevron-icon {
        display: none;
    }

    .cart:not([data-cartitems=''])::after {
        color: #000;
        right: 50%;
        top: 0.25rem;
        transform: translateX(140%);
    }

    .outlet-wrapper {
        min-height: calc(100svh - 9.63rem);
    }
}