.subscribe-wrapper {
    width: min(31rem, 100%);
    height: 100%;
    padding-inline: 0.5rem;
    display: flex;
    gap: 2.5rem;
    overflow: hidden;
}

.subscribe-wrapper .add-form {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    transition: margin-left 0.25s ease;
}

.subscribe-wrapper.hidden .add-form.first {
    margin-left: calc(-100% - 2.5rem);
}

.subscribe-wrapper .add-form.disable {
    pointer-events: none;
}

.autofill {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.fullName-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
}

.add-form input,
.add-form textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 1rem;
}

.add-form input[type="number"]::-webkit-outer-spin-button,
.add-form input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.add-form input[type="number"] {
    appearance: textfield;
}

.add-form textarea {
    flex-grow: 1;
    resize: none;
}

.state-city-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.state-city-div input[name="district"],
.fullName-div input[name="title"] {
    display: none;
}

.fullName-div .small {
    min-width: 5rem;
}

.state-city-div .small {
    width: calc(50% - clamp(1rem, 17.5vw - 49px, 1.5rem));
}

.state-city-div .dropdown-container.small .dropdown-btn,
.fullName-div .dropdown-container.small .dropdown-btn {
    width: 100%;
    aspect-ratio: unset;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    justify-content: space-between;
    background-color: #fff;
    transition: background-color 0.25s ease;
}

.state-city-div .dropdown-container.small .dropdown-btn:hover,
.fullName-div .dropdown-container.small .dropdown-btn:hover {
    background-color: rgba(215, 215, 215, 0.5);
}

.state-city-div .dropdown-container.small .dropdown {
    top: unset;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.25rem;
}

.add-form .save-add-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    width: 100%;
    padding: 0 0.5rem;
}

.add-form .save-add-div label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    cursor: pointer;
}

.add-form .save-add-div input {
    cursor: pointer;
    accent-color: #000;
}

.add-form .save-add-div a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

.autofill,
.add-btn {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 10px;
    background-color: #000;
    font-size: 1.1rem;
    font-weight: 500;
    color: #eaeaea;
    cursor: pointer;
    transition: transform 0.25s ease, background-color 0.25s ease;
}

.autofill:hover,
.add-btn:hover {
    background-color: #353535;
}

.autofill:active,
.add-btn:active {
    transform: scale(0.95);
}

.add-card-container {
    flex-grow: 1;
    height: 24rem;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    background-color: #fff;
    overflow-y: scroll;
    border-radius: 10px;
}

.add-card-container>h2 {
    margin-block: auto;
    align-self: center;
}

.add-card-container label {
    width: 100%;
    border-radius: 10px;
    background-color: #eaeaea;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: transform 0.25s ease, background-color 0.25s ease;
}

.add-card-container label:hover {
    background-color: #dadada;
}

.add-card-container label:active {
    transform: scale(0.95);
}

.add-card-container label input {
    accent-color: #000;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: fit-content;
    cursor: pointer;
}

.add-card-container label h4 {
    font-size: 1.1rem;
}

.add-card-container label p {
    line-height: 150%;
}

.new-add {
    align-self: flex-start;
    color: #000;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    margin-left: 0.5rem;
}

.add-form .save-add-div a:hover,
.new-add:hover {
    text-decoration: underline;
}

@media (pointer: coarse) {

    .autofill:hover,
    .add-btn:hover {
        background-color: #000;
    }

    .autofill:active,
    .add-btn:active {
        background-color: #353535;
        transform: scale(0.95);
    }

    .state-city-div .dropdown-container.small .dropdown-btn:hover,
    .fullName-div .dropdown-container.small .dropdown-btn:hover {
        background-color: #fff;
    }

    .state-city-div .dropdown-container.small .dropdown-btn:active,
    .fullName-div .dropdown-container.small .dropdown-btn:active {
        background-color: rgba(215, 215, 215, 0.5);
    }

    .add-form .save-add-div a:hover,
    .new-add:hover {
        text-decoration: none;
    }

    .add-form .save-add-div a:active,
    .new-add:active {
        text-decoration: underline;
    }

    .add-card-container label:hover {
        background-color: #eaeaea;
    }

    .add-card-container label:active {
        background-color: #dadada;
        transform: scale(0.95);
    }
}